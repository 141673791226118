import {
  Api,
  AuthenticationOptions,
} from '@mesa-labs/mesa-api';
import { Mutex } from 'async-mutex';

import cognitoService from '../../cognito';
import { isApiError } from './types';

export const getIdToken = async (): Promise<string | undefined> => {
  try {
    return await cognitoService.getIdToken();
  } catch (_err) {
    return undefined;
  }
};

export const prepareHeaders = async (headers: Headers) => {
  const idToken = await getIdToken();
  if (idToken) {
    headers.set('Authorization', `Bearer ${idToken}`);
  }

  headers.set('mode', 'cors');
  headers.set('cache', 'no-cache');
  headers.set('credentials', 'same-origin');
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  return headers;
};

export const authenticate = async () => {
  const session = await cognitoService.getCurrentSession();
  const idToken = await session?.getIdToken();

  return {
    token: await idToken?.getJwtToken() || '',
    expiresAt: await idToken?.getExpiration() || 0,
  };
};

type ApiFactory<T extends Api> = (options?: AuthenticationOptions) => T;

export const useApi = <T extends Api>(factory: ApiFactory<T>) => {
  const options = { authenticate };

  return factory(options);
};

export const getFetchErrorMessage = (error: any) => (isApiError(error.data) ? error.data.message : JSON.stringify(error));

export const StatisticsMutex = new Mutex();
export const ExternalVendorStatisticsMutex = new Mutex();
