import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Card,
  CardContainer, CardTitle,
  DownloadButton,
  PaginationFooter,
  ResourceCard,
  ResourceSection,
  Table,
} from '@mesa-labs/mesa-ui';
import { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import {
  useGetExternalVendorQuery, useGetExternalVendorClientsQuery,
  useGetExternalVendorInvoicesQuery,
  exportExternalVendorInvoices,
} from '../../redux/api/externalVendors';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateCurrentExternalVendorId,
  updateExternalClientsLimit, updateExternalClientsPage, updateExternalClientsSortDirection, updateExternalClientsSortField, updateExternalClientsTotalPages,
  updateExternalInvoicesLimit, updateExternalInvoicesPage, updateExternalInvoicesServicedByMesa, updateExternalInvoicesSortDirection, updateExternalInvoicesSortField, updateExternalInvoicesTotalPages,
} from '../../redux/slices/externalVendors';
import { DefaultPaginationLimits } from './PaginationLimits';
import { FilterCell, FilterControls, FilterControlsButtonContainer, FilterRow, FilterSection } from './Filters';
import { OptionalBooleanDropdown } from './OptionalDropdown';
import { asExportFilter, stackedHorizontalBarChartOptions } from '../../utils';
import ReactApexChart from 'react-apexcharts';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type ExternalVendorPageTemplateProps = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalVendorPageTemplate(props: ExternalVendorPageTemplateProps): React.ReactElement | null {
  const { businessEntityScope } = props;
  const { id: externalVendorId, partnerId } = useParams();
  const dispatch = useDispatch();
  const externalClientsPage = useSelector((state) => state.externalVendors.externalClientsPage) || 1;
  const externalClientsTotalPages = useSelector((state) => state.externalVendors.externalClientsTotalPages);
  const externalClientsLimit = useSelector((state) => state.externalVendors.externalClientsLimit) || DefaultPaginationLimits[0];
  const externalClientsSortField = useSelector((state) => state.externalVendors.externalClientsSortField);
  const externalClientsSortDirection = useSelector((state) => state.externalVendors.externalClientsSortDirection);
  const externalInvoicesServicedByMesa = useSelector((state) => state.externalVendors.externalInvoicesServicedByMesa);
  const externalInvoicesPage = useSelector((state) => state.externalVendors.externalInvoicesPage) || 1;
  const externalInvoicesTotalPages = useSelector((state) => state.externalVendors.externalInvoicesTotalPages);
  const externalInvoicesLimit = useSelector((state) => state.externalVendors.externalInvoicesLimit) || DefaultPaginationLimits[0];
  const externalInvoicesSortField = useSelector((state) => state.externalVendors.externalInvoicesSortField);
  const externalInvoicesSortDirection = useSelector((state) => state.externalVendors.externalInvoicesSortDirection);
  const partnerIdParam = parseInt(partnerId || '', 10);

  useEffect(() => {
    if (externalVendorId) {
      dispatch(updateCurrentExternalVendorId(externalVendorId));
    }
  }, [externalVendorId]);

  const {
    data: externalVendor,
  } = useGetExternalVendorQuery({
    partnerId: partnerIdParam,
    externalVendorId: externalVendorId!,
    businessEntityScope
  }, { skip: !externalVendorId });

  const {
    data: {
      data: externalClients,
      total: totalExternalClients,
    } = {},
  } = useGetExternalVendorClientsQuery({
    partnerId: partnerIdParam,
    externalVendorId: externalVendorId!,
    excluded: false,
    page: externalClientsPage,
    limit: externalClientsLimit.value,
    sortField: externalClientsSortField,
    sortDirection: externalClientsSortDirection,
    businessEntityScope
  }, { skip: !externalVendorId });

  useEffect(() => {
    if (totalExternalClients !== undefined) {
      const limitValue = externalClientsLimit.value || externalClientsLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalClientsTotalPages(Math.ceil(totalExternalClients / limitValue)));
    }
  }, [totalExternalClients, externalClientsLimit]);

  const externalInvoicesFilter = {
    partnerId: partnerIdParam,
    externalVendorId: externalVendorId!,
    businessEntityScope,
    servicedByMesa: externalInvoicesServicedByMesa,
  };
  const {
    data: {
      data: externalInvoices,
      total: totalExternalInvoices,
    } = {}
  } = useGetExternalVendorInvoicesQuery(externalInvoicesFilter, { skip: !externalVendorId });

  useEffect(() => {
    if (totalExternalInvoices !== undefined) {
      const limitValue = externalInvoicesLimit.value || externalInvoicesLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalInvoicesTotalPages(Math.ceil(totalExternalInvoices / limitValue)));
    }
  }, [totalExternalInvoices, externalInvoicesLimit]);

  const timelineSeries: ApexAxisChartSeries = useMemo(() => {
    if (!externalVendor) {
      return [];
    }

    return [
      {
        name: 'Weighted Average Approval Time',
        data: [{ x: 'Time (Days)', y: externalVendor.weightedAverageApprovalTime }]
      },
      {
        name: 'Weighted Average Import Lateness',
        data: [{ x: 'Time (Days)', y: externalVendor.weightedAverageImportLateness }]
      },
      {
        name: 'Weighted Average Pre-Float Period',
        data: [{ x: 'Time (Days)', y: externalVendor.weightedAveragePreFloatPeriod }]
      },
      {
        name: 'Weighted Average Float Period',
        data: [{ x: 'Time (Days)', y: externalVendor.weightedAverageFloatPeriod }]
      },
    ];
  }, [externalVendor]);

  if (!externalVendor) {
    return null;
  }

  const externalVendorItems: Array<[string, Array<string | ResourceItemProp>]> =
  [
    ['Basic Info',
      [
        { key: 'parterName', label: 'Partner' },
        { key: 'externalVendorId' },
        { key: 'isInternalVendor', label: 'Mesa?' },
        { key: 'internalVendorId', label: 'Internal Vendor Link', type: 'link'},
        'email',
        'phoneNumber',
        { key: ['address', 'addressAdditional', 'city', 'state', 'zip', 'countryCode'], width: '50%' },
        { key: 'paymentMethod', type: 'enum' },
        'taxId',
        'locale',
        'withholdingPercent',
        'classification',
        'serviceType',
        { key: 'diverse', label: 'Diverse?' },
        {key: 'hubSpotRecordId', width: '50%'},
      ]
    ],
    ['Invoice Counts',
      [
        { key: 'eligibleInvoiceCountT6m', label: 'Eligible Invoice Count (T6M)' },
        { key: 'eligibleInvoiceCountT12m', label: 'Eligible Invoice Count (T12M)', width: '75%' },
        { key: 'invoicesAccelerated', label: 'Invoices Accelerated Count' },
      ]
    ],
    ['Invoice Spend/Acceleration',
      [
        { key: 'totalInvoiceAmountPriorYear', type: 'currency' },
        { key: 'totalInvoiceAmountCurrentYear', type: 'currency', width: '75%'},
        { key: 'eligibleInvoiceSpend', label: 'Eligible Spend (T12M)', type: 'currency' },
        { key: 'eligibleInvoiceSpendT6m', label: 'Eligible Spend (T6M)', type: 'currency', width: '75%' },
        { key: 'monthlyInvoiceSpendT6m', label: 'Monthly Spend (T6M)', type: 'currency' },
        { key: 'monthlyInvoiceSpendStdDevT6m', label: 'Monthly Spend Standard Deviation (T6M)', type: 'currency', width: '75%' },
        { key: 'monthlyInvoiceSpendT12m', label: 'Monthly Spend (T12M)', type: 'currency' },
        { key: 'monthlyInvoiceSpendStdDevT12m', label: 'Monthly Spend Standard Deviation (T12M)', type: 'currency', width: '75%' },
        { key: 'invoiceAmountAccelerated', label: 'Invoices Accelerated (Amount)', type: 'currency' },
        { key: 'revenueGenerated', label: 'Revenue Generated', type: 'currency', width: '75%' },
      ]
    ],
    ['Timeline',
      [
        { key: 'weightedEligibleAveragePaymentTenor', label: 'Eligible WAPT (T12M)' },
        { key: 'weightedEligibleAveragePaymentTenorT6m', label: 'Eligible WAPT (T6M)', width: '75%' },
        { key: 'weightedEligibleAverageInvoiceTenor', label: 'Eligible WAIT (T12M)' },
        { key: 'weightedEligibleAverageInvoiceTenorT6m', label: 'Eligible WAIT (T6M)', width: '75%' },
        { key: 'weightedEligibleAverageLedgerLatenessT6m', label: 'Eligible WALL (T6M)' },
        { key: 'weightedEligibleAverageLedgerLatenessT12m', label: 'Eligible WALL (T12M)', width: '75%'},
        { key: 'weightedAverageApprovalTime', label: 'Weighted Average Approval Time (Internal)' },
        { key: 'weightedAverageImportLateness', label: 'Weighted Average Import Lateness (Internal)' },
        { key: 'weightedAveragePreFloatPeriod', label: 'Weighted Average Pre-Float Period (Internal)' },
        { key: 'weightedAverageFloatPeriod', label: 'Weighted Average Float Period (Internal)' },
      ]
    ],
    ['Yield, Failure %',
      [
        { key: 'weightedAverageDiscountRate', type: 'percentage', label: 'Weighted Discount Rate' },
        { key: 'weightedAverageApr', type: 'percentage', label: 'Weighted Average APR', width: '75%' },
        { key: 'weightedEligibleAverageNet30AprT6m', type: 'percentage', label: 'Weighted Net 30 APR (T6M)' },
        { key: 'weightedEligibleAverageNet30AprT12m', type: 'percentage', label: 'Weighted Net 30 APR (T12M)', width: '75%' },
        { key: 'weightedEligibleAverageNet30YieldPricingT6m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T6M)' },
        { key: 'weightedEligibleAverageNet30YieldPricingT12m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T12M)', width: '75%' },
        { key: 'percentageProjectedEligibleInvoiceSpendT6m', type: 'percentage', label: '% Projected Eligible Spend (T6M)', width: '100%' },
        { key: 'internalPercentageFailingNet30', type: 'percentage', label: '% of Internal Invoices Failing Net-30' },
        { key: 'internalPercentageFailingNet60', type: 'percentage', label: '% of Internal Invoices Failing Net-60' },
        { key: 'internalPercentageSettledWithin3', type: 'percentage', label: '% of Internal Invoices Settled In 3', width: '50%' },
        { key: 'externalPercentageFailingNet30', type: 'percentage', label: '% of External Invoices Failing Net-30' },
        { key: 'externalPercentageFailingNet60', type: 'percentage', label: '% of External Invoices Failing Net-60' },
        { key: 'externalPercentageSettledWithin3', type: 'percentage', label: '% of External Invoices Settled In 3', width: '50%' },
      ]
    ],
  ];

  const externalVendorAuditItems: (string | ResourceItemProp)[] = [
    { key: 'signedUpAt', type: 'datetime' },
    { key: 'activatedAt', type: 'datetime' },
    { key: 'deactivatedAt', type: 'datetime', width: '50%' },
  ];

  return (
    <ClientPageContainer>
      <CardContainer>
        <ResourceCard
          resource={{
            ...externalVendor,
            internalVendorIdLocation: externalVendor.internalVendorId ? `vendors/${externalVendor.internalVendorId}` : undefined,
            parterName: api.getPartnerName(externalVendor.partnerId),
            isInternalVendor: !!externalVendor.internalVendorId && !externalVendor.deactivatedAt,
            currency: 'USD', // TODO: MESA-1457: Multi-currency support
          }}
          title={externalVendor.externalVendorName || externalVendor.externalVendorId}
          items={[]}
          sectionedItems={externalVendorItems}
          auditItems={externalVendorAuditItems}
          columns={4}
        />
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Weighted Average Invoice Timeline (Internal)</CardTitle>
          <ReactApexChart options={stackedHorizontalBarChartOptions()} series={timelineSeries} type="bar"
            height={350} />
        </Card>
      </CardContainer>
      <ResourceSection
        title="Clients (T12M - Included Only)"
      >
        <Table
          columns={[
            {
              key: 'partnerName',
              sortField: 'partnerId',
              label: 'Partner',
              width: '10%',
            },
            {
              key: 'externalClientId',
              sortField: 'externalClientId',
              label: 'External Client Id',
              width: '10%',
            },
            {
              key: 'externalClientName',
              sortField: 'externalClientName',
              label: 'External Client Name',
              width: '20%',
            },
            {
              key: 'segment',
              sortField: 'segment',
              label: 'Client Segment',
              width: '20%',
            },
            {
              key: 'totalInvoiceSpend',
              sortField: 'totalInvoiceSpend',
              label: 'Vendor Invoice Spend (T12M)',
              type: 'currency',
              width: '20%',
            },
            {
              key: 'totalInvoiceSpendT6m',
              sortField: 'totalInvoiceSpendT6m',
              label: 'Vendor Invoice Spend (T6M)',
              type: 'currency',
              width: '20%',
            },
          ]}
          currentSortColumn={externalClientsSortField}
          currentSortDirection={externalClientsSortDirection}
          setSortField={(field: string) => dispatch(updateExternalClientsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalClientsSortDirection(direction))}
          rows={
            (externalClients || []).map((externalClient) => ({
              ...externalClient,
              id: `${externalClient.partnerId}:${externalClient.externalClientId}`,
              partnerName: api.getPartnerName(externalClient.partnerId),
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalClientsLimit}
              onChange={(item) => {
                dispatch(updateExternalClientsLimit(item));
                dispatch(updateExternalClientsPage(1));
              }}
              currentPage={externalClientsPage}
              totalPages={externalClientsTotalPages || 0}
              onPrev={() => dispatch(updateExternalClientsPage(externalClientsPage - 1))}
              onNext={() => dispatch(updateExternalClientsPage(externalClientsPage + 1))}
            />
          )}
        />
      </ResourceSection>

      <ResourceSection
        title="Invoices"
      >
        <FilterSection
          filterControl={(
            <FilterControls>
              <FilterControlsButtonContainer>
                <DownloadButton
                  text="Download CSV"
                  data={() => exportExternalVendorInvoices(partnerIdParam, externalVendorId!, {
                    ...asExportFilter(externalInvoicesFilter),
                  })}
                  fileName={`external-vendor-${externalVendorId}-invoicess.csv`}
                />
              </FilterControlsButtonContainer>
            </FilterControls>
          )}
        >
          {businessEntityScope !== api.BusinessEntityScope.Internal ? (
            <FilterRow columns={4}>
              <FilterCell>
                <OptionalBooleanDropdown
                  label="Serviced By Mesa"
                  selectedOption={externalInvoicesServicedByMesa}
                  onChange={(value) => dispatch(updateExternalInvoicesServicedByMesa(value))}
                />
              </FilterCell>
            </FilterRow>
          ) : (<></>)}
        </FilterSection>

        <Table
          columns={[
            {
              key: 'invoiceNumber',
              sortField: 'invoiceNumber',
              label: 'Invoice Number',
              width: '20%',
            },
            {
              key: 'invoiceDate',
              sortField: 'invoiceDate',
              label: 'Invoice Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'generalLedgerDate',
              sortField: 'generalLedgerDate',
              label: 'General Ledger Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'dueDate',
              sortField: 'dueDate',
              label: 'Due Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'paymentDate',
              sortField: 'paymentDate',
              label: 'Payent Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'grossAmount',
              sortField: 'grossAmount',
              label: 'Amount',
              width: '10%',
              type: 'currency',
            },
            {
              key: 'servicedByMesa',
              sortField: 'servicedByMesa',
              label: 'Service By Mesa',
              width: '10%',
            },
          ]}
          currentSortColumn={externalInvoicesSortField}
          currentSortDirection={externalInvoicesSortDirection}
          setSortField={(field: string) => dispatch(updateExternalInvoicesSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalInvoicesSortDirection(direction))}
          rows={
            (externalInvoices || []).map((externalInvoice) => ({
              ...externalInvoice,
              id: `${externalInvoice.partnerId}:${externalInvoice.externalClientId}:${externalInvoice.externalDocumentId}:${externalInvoice.documentType}`,
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalInvoicesLimit}
              onChange={(item) => {
                dispatch(updateExternalInvoicesLimit(item));
                dispatch(updateExternalInvoicesPage(1));
              }}
              currentPage={externalInvoicesPage}
              totalPages={externalInvoicesTotalPages || 0}
              onPrev={() => dispatch(updateExternalInvoicesPage(externalInvoicesPage - 1))}
              onNext={() => dispatch(updateExternalInvoicesPage(externalInvoicesPage + 1))}
            />
          )}
        />
      </ResourceSection>
    </ClientPageContainer >
  );
}

export default ExternalVendorPageTemplate;
