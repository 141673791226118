import { types as api } from '@mesa-labs/mesa-api';
import * as types from '@mesa-labs/mesa-api/dist/types';
import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  DownloadButton,
  Input,
  PaginationFooter,
  Search,
  Table,
} from '@mesa-labs/mesa-ui';

import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

import {
  asExportFilter, internalVendorRowStyling,
  Selection,
} from '../../utils';
import { DefaultPaginationLimits } from '../common/PaginationLimits';
import { exportAllExternalVendors, useGetAllExternalVendorsQuery } from '../../redux/api/externalVendors';
import {
  FilterCell, FilterControls, FilterRow, FilterSection, FilterTitle,
} from '../common/Filters';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateIsPreferredVendor,
  updateIsInternalVendor,
  updateIsVendorInHubSpot,
  updateLimit, updateMaximumWeightedEligibleAveragePaymentTenor, updateMinimumPercentageProjectedEligibleInvoiceSpend, updateMinimumEligibleInvoiceSpend,
  updateMinimumWeightedEligibleAveragePaymentTenor,
  updatePage, updateSearchTerm, updateSortDirection, updateSortField, updateTotalPages, updateUseLeadListFilters, updateVendorLocale
} from '../../redux/slices/externalVendors';
import PartnerSelect from '../common/PartnerSelect';
import VendorLocaleSelect from '../common/VendorLocaleSelect';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';

const VendorsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BooleanSelectionOptions: readonly Selection[] = [
  {
    label: '(Not Set)',
    value: '',
  },
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
];

const ExternalVendorColumns: ColumnProp[] = [
  {
    key: 'partnerName',
    sortField: 'partnerName',
    label: 'Partner',
    width: '3%',
  },
  {
    key: 'externalVendorId',
    sortField: 'externalVendorId',
    label: 'External Id',
    width: '7%',
  },
  {
    key: 'externalVendorName',
    sortField: 'externalVendorName',
    label: 'External Name',
    width: '10%',
  },
  {
    key: 'eligibleInvoiceSpend',
    sortField: 'eligibleInvoiceSpend',
    label: 'Eligible Spend (T12M)',
    type: 'currency',
    width: '9%',
  },
  {
    key: 'eligibleInvoiceSpendT6m',
    sortField: 'eligibleInvoiceSpendT6m',
    label: 'Eligible Spend (T6M)',
    type: 'currency',
    width: '9%',
  },
  {
    key: 'percentageProjectedEligibleInvoiceSpendT6m',
    sortField: 'percentageProjectedEligibleInvoiceSpendT6m',
    label: '% Projected Eligible Spend (T6M)',
    width: '9%',
  },
  {
    key: 'weightedEligibleAveragePaymentTenor',
    sortField: 'weightedEligibleAveragePaymentTenor',
    label: 'Eligible WAPT (T12M)',
    width: '9%',
  },
  {
    key: 'weightedEligibleAverageInvoiceTenor',
    sortField: 'weightedEligibleAverageInvoiceTenor',
    label: 'Eligible WAIT (T12M)',
    width: '9%',
  },
  {
    key: 'weightedEligibleAverageLedgerLatenessT12m',
    sortField: 'weightedEligibleAverageLedgerLatenessT12m',
    label: 'Eligible WALL (T12M)',
    width: '9%',
  },
  {
    key: 'isInternalVendor',
    sortField: 'internalVendorId',
    label: 'Mesa?',
    width: '7%',
  },
  {
    key: 'classification',
    sortField: 'classification',
    label: 'Classification',
    width: '5%',
  },
  {
    key: 'signedUpAt',
    sortField: 'signedUpAt',
    label: 'Sign Up Date',
    width: '7%',
    type: 'date',
  },
  {
    key: 'activatedAt',
    sortField: 'activatedAt',
    label: 'Activation Date',
    width: '7%',
    type: 'date',
  },
];

type ExternalVendorsPageTemplateProps = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalVendorsPageTemplate(props: ExternalVendorsPageTemplateProps): React.ReactElement {
  const { businessEntityScope } = props;
  const qualifier = businessEntityScope === api.BusinessEntityScope.External ? 'external' : 'internal';
  const [partner, setPartner] = useState<api.Partners>(api.Partners.JLL);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const vendorLocale = useSelector((state) => state.externalVendors.vendorLocale);
  const searchTerm = useSelector((state) => state.externalVendors.searchTerm);
  const useLeadListFilters = useSelector((state) => state.externalVendors.useLeadListFilters) || false;
  const isInternalVendor = useSelector((state) => state.externalVendors.isInternalVendor);
  const isPreferredVendor = useSelector((state) => state.externalVendors.isPreferredVendor);
  const isVendorInHubSpot = useSelector((state) => state.externalVendors.isVendorInHubSpot);
  const minimumPercentageProjectedEligibleInvoiceSpend = useSelector((state) => state.externalVendors.minimumPercentageProjectedEligibleInvoiceSpend);
  const minimumEligibleInvoiceSpend = useSelector((state) => state.externalVendors.minimumEligibleInvoiceSpend);
  const minimumWeightedEligibleAveragePaymentTenor = useSelector((state) => state.externalVendors.minimumWeightedEligibleAveragePaymentTenor);
  const maximumWeightedEligibleAveragePaymentTenor = useSelector((state) => state.externalVendors.maximumWeightedEligibleAveragePaymentTenor);
  const page = useSelector((state) => state.externalVendors.page) || 1;
  const totalPages = useSelector((state) => state.externalVendors.totalPages);
  const limit = useSelector((state) => state.externalVendors.limit) || DefaultPaginationLimits[0];
  const sortField = useSelector((state) => state.externalVendors.sortField);
  const sortDirection = useSelector((state) => state.externalVendors.sortDirection);

  useSyncQueryStringParamsToRedux({ sliceName: 'externalVendors' });

  const [searchInput, setSearchInput] = useState<string | undefined>(searchTerm);
  const [minimumPercentageProjectedEligibleInvoiceSpendInput, setMinimumPercentageProjectedEligibleInvoiceSpendInput] = useState<string>(minimumPercentageProjectedEligibleInvoiceSpend?.toString() || '');
  const [minimumEligibleInvoiceSpendInput, setMinimumEligibleInvoiceSpendInput] = useState<string>(minimumEligibleInvoiceSpend?.toString() || '');
  const [minimumWeightedEligibleAveragePaymentTenorInput, setMinimumWeightedEligibleAveragePaymentTenorInput] = useState<string>(minimumWeightedEligibleAveragePaymentTenor?.toString() || '');
  const [maximumWeightedEligibleAveragePaymentTenorInput, setMaximumWeightedEligibleAveragePaymentTenorInput] = useState<string>(maximumWeightedEligibleAveragePaymentTenor?.toString() || '');
  const [isInternalVendorInput, setIsInternalVendorInput] = useState<Selection>(BooleanSelectionOptions.find((o) => o.value === isInternalVendor?.toString() || '') || BooleanSelectionOptions[0]);
  const [isPreferredVendorInput, setIsPreferredVendorInput] = useState<Selection>(BooleanSelectionOptions.find((o) => o.value === isPreferredVendor?.toString() || '') || BooleanSelectionOptions[0]);
  const [isVendorInHubSpotInput, setIsVendorInHubSpotInput] = useState<Selection>(BooleanSelectionOptions.find((o) => o.value === isVendorInHubSpot?.toString() || '') || BooleanSelectionOptions[0]);

  const filters: types.ExternalVendorFilterParams & { partnerId: number } = {
    partnerId: partner,
    limit: limit.value,
    page,
    sortField,
    sortDirection,
    search: searchTerm,
    isInternalVendor,
    isPreferredVendor,
    isVendorInHubSpot,
    minimumPercentageProjectedEligibleInvoiceSpendT6m: minimumPercentageProjectedEligibleInvoiceSpend,
    minimumEligibleInvoiceSpendT6m: minimumEligibleInvoiceSpend,
    minimumWeightedEligibleAveragePaymentTenor,
    maximumWeightedEligibleAveragePaymentTenor,
    locale: vendorLocale,
    businessEntityScope
  } as types.ExternalVendorFilterParams & { partnerId: number };

  const {
    data: {
      data: externalVendors = [],
      total: totalVendors,
    } = {},
  } = useGetAllExternalVendorsQuery(filters);

  const rows = externalVendors.map((externalVendor) => ({
    partnerName: api.Partners[externalVendor.partnerId],
    partnerId: externalVendor.partnerId,
    id: `${externalVendor.partnerId}:${externalVendor.externalVendorId}`,
    externalVendorId: externalVendor.externalVendorId,
    externalVendorName: externalVendor.externalVendorName,
    eligibleInvoiceSpend: externalVendor.eligibleInvoiceSpend,
    eligibleInvoiceSpendT6m: externalVendor.eligibleInvoiceSpendT6m,
    percentageProjectedEligibleInvoiceSpendT6m: externalVendor.percentageProjectedEligibleInvoiceSpendT6m,
    weightedEligibleAveragePaymentTenor: externalVendor.weightedEligibleAveragePaymentTenor,
    weightedEligibleAverageInvoiceTenor: externalVendor.weightedEligibleAverageInvoiceTenor,
    weightedEligibleAverageLedgerLatenessT12m: externalVendor.weightedEligibleAverageLedgerLatenessT12m,
    isInternalVendor: !!externalVendor.internalVendorId && !externalVendor.deactivatedAt,
    preferred: !!externalVendor.preferred,
    currency: 'USD', // TODO: MESA-1457: Multi-currency support
    activatedAt: externalVendor.activatedAt,
    signedUpAt: externalVendor.signedUpAt,
    internalVendorId: externalVendor.internalVendorId
  }));

  useEffect(() => {
    if (totalVendors !== undefined) {
      const limitValue = limit.value || limit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateTotalPages(Math.ceil(totalVendors / limitValue)));
    }
  }, [totalVendors, limit.value]);

  useEffect(() => {
    if (totalPages === undefined && page !== 1) {
      dispatch(updatePage(1));
    }
  }, []);

  const onUseLeadListFiltersChanged = (value: boolean) => {
    if (value) {
      setMinimumPercentageProjectedEligibleInvoiceSpendInput('66');
      setMinimumEligibleInvoiceSpendInput('10000');
      setMinimumWeightedEligibleAveragePaymentTenorInput('60');
      setMaximumWeightedEligibleAveragePaymentTenorInput('120');
      setIsInternalVendorInput(BooleanSelectionOptions[2]);
      dispatch(updateIsInternalVendor(false));
      setIsPreferredVendorInput(BooleanSelectionOptions[2]);
      dispatch(updateIsPreferredVendor(false));
      setIsVendorInHubSpotInput(BooleanSelectionOptions[2]);
      dispatch(updateIsVendorInHubSpot(false));
    } else {
      setMinimumPercentageProjectedEligibleInvoiceSpendInput('');
      setMinimumEligibleInvoiceSpendInput('');
      setMinimumWeightedEligibleAveragePaymentTenorInput('');
      setMaximumWeightedEligibleAveragePaymentTenorInput('');
      setIsInternalVendorInput(BooleanSelectionOptions[0]);
      dispatch(updateIsInternalVendor(undefined));
      setIsPreferredVendorInput(BooleanSelectionOptions[0]);
      dispatch(updateIsPreferredVendor(undefined));
      setIsVendorInHubSpotInput(BooleanSelectionOptions[0]);
      dispatch(updateIsVendorInHubSpot(undefined));
    }
    dispatch(updateUseLeadListFilters(value));
    dispatch(updatePage(1));
  };

  const onIsInternalVendorChanged = (selection: SingleValue<Selection>) => {
    if (selection?.value === 'true') {
      setIsInternalVendorInput(BooleanSelectionOptions[1]);
      dispatch(updateIsInternalVendor(true));
    } else if (selection?.value === 'false') {
      setIsInternalVendorInput(BooleanSelectionOptions[2]);
      dispatch(updateIsInternalVendor(false));
    } else {
      setIsInternalVendorInput(BooleanSelectionOptions[0]);
      dispatch(updateIsInternalVendor(undefined));
    }
    dispatch(updateUseLeadListFilters(false));
    dispatch(updatePage(1));
  };

  const onIsPreferredVendorChanged = (selection: SingleValue<Selection>) => {
    if (selection?.value === 'true') {
      setIsPreferredVendorInput(BooleanSelectionOptions[1]);
      dispatch(updateIsPreferredVendor(true));
    } else if (selection?.value === 'false') {
      setIsPreferredVendorInput(BooleanSelectionOptions[2]);
      dispatch(updateIsPreferredVendor(false));
    } else {
      setIsPreferredVendorInput(BooleanSelectionOptions[0]);
      dispatch(updateIsPreferredVendor(undefined));
    }
    dispatch(updateUseLeadListFilters(false));
    dispatch(updatePage(1));
  };

  const onIsVendorInHubSpotChanged = (selection: SingleValue<Selection>) => {
    if (selection?.value === 'true') {
      setIsVendorInHubSpotInput(BooleanSelectionOptions[1]);
      dispatch(updateIsVendorInHubSpot(true));
    } else if (selection?.value === 'false') {
      setIsVendorInHubSpotInput(BooleanSelectionOptions[2]);
      dispatch(updateIsVendorInHubSpot(false));
    } else {
      setIsVendorInHubSpotInput(BooleanSelectionOptions[0]);
      dispatch(updateIsVendorInHubSpot(undefined));
    }
    dispatch(updateUseLeadListFilters(false));
    dispatch(updatePage(1));
  };

  const onMinimumPercentageProjectedEligibleInvoiceSpendChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMinimumPercentageProjectedEligibleInvoiceSpendInput(event.currentTarget.value);
    dispatch(updateUseLeadListFilters(false));
  };

  const onMinimumPercentageProjectedEligibleInvoiceSpendFinalized = (value: string) => {
    const numericValue = parseInt(value, 10);
    dispatch(updateMinimumPercentageProjectedEligibleInvoiceSpend(Number.isNaN(numericValue) ? undefined : numericValue));
    dispatch(updatePage(1));
  };

  const onMinimumEligibleInvoiceSpendChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMinimumEligibleInvoiceSpendInput(event.currentTarget.value);
    dispatch(updateUseLeadListFilters(false));
  };

  const onMinimumEligibleInvoiceSpendFinalized = (value: string) => {
    const numericValue = parseInt(value, 10);
    dispatch(updateMinimumEligibleInvoiceSpend(Number.isNaN(numericValue) ? undefined : numericValue));
    dispatch(updatePage(1));
  };

  const onMinimumWeightedEligibleAveragePaymentTenorChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMinimumWeightedEligibleAveragePaymentTenorInput(event.currentTarget.value);
    dispatch(updateUseLeadListFilters(false));
  };

  const onMinimumWeightedEligibleAveragePaymentTenorFinalized = (value: string) => {
    const numericValue = parseInt(value, 10);
    dispatch(updateMinimumWeightedEligibleAveragePaymentTenor(Number.isNaN(numericValue) ? undefined : numericValue));
    dispatch(updatePage(1));
  };

  const onMaximumWeightedEligibleAveragePaymentTenorChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMaximumWeightedEligibleAveragePaymentTenorInput(event.currentTarget.value);
    dispatch(updateUseLeadListFilters(false));
  };

  const onMaximumWeightedEligibleAveragePaymentTenorFinalized = (value: string) => {
    const numericValue = parseInt(value, 10);
    dispatch(updateMaximumWeightedEligibleAveragePaymentTenor(Number.isNaN(numericValue) ? undefined : numericValue));
    dispatch(updatePage(1));
  };

  const appliedFilters = useMemo(() => [isInternalVendor, isPreferredVendor, isVendorInHubSpot, minimumPercentageProjectedEligibleInvoiceSpend, minimumEligibleInvoiceSpend, minimumWeightedEligibleAveragePaymentTenor, maximumWeightedEligibleAveragePaymentTenor]
    .reduce((acc: number, curr) => acc + (curr !== undefined ? 1 : 0), 0), [isInternalVendor, isPreferredVendor, isVendorInHubSpot, minimumPercentageProjectedEligibleInvoiceSpend, minimumEligibleInvoiceSpend, minimumWeightedEligibleAveragePaymentTenor, maximumWeightedEligibleAveragePaymentTenor]);

  const searchParam = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('search');
  }, [location.search]);

  useEffect(() => {
    if (searchParam) {
      setSearchInput(searchParam);
      dispatch(updateSearchTerm(searchParam));
      onUseLeadListFiltersChanged(false);
    }
  }, [searchParam]);

  return (
    <VendorsPageContainer>
      <FilterSection
        filterControl={(
          <FilterControls>
            <Search
              placeholder="Search by External Vendor Name. Press Enter to search, Escape to clear."
              value={searchInput || ''}
              onChange={(value) => setSearchInput(value)}
              onSubmit={(value) => dispatch(updateSearchTerm(value))}
              onFinalize={(value) => dispatch(updateSearchTerm(value))}
              expanded
              width="550px"
            />
            {/* TODO: remove ! once backend supports optional partner ID */}
            <DownloadButton
              text="Download CSV"
              data={() => exportAllExternalVendors(partner!, {
                ...asExportFilter(filters),
              })}
              fileName="external-vendors.csv"
            />
          </FilterControls>
        )}
        appliedFilters={appliedFilters}
        isInitiallyOpen={!!searchParam}
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Partner</FilterTitle>
            <PartnerSelect
              selectedPartner={partner}
              onSelectedPartnerChange={(p) => setPartner(p!)}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Vendor Locale</FilterTitle>
            <VendorLocaleSelect
              selectedVendorLocale={vendorLocale}
              onSelectedVendorLocaleChange={(vl) => dispatch(updateVendorLocale(vl))}
              allowAllVendorLocales
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Use Lead List Filters</FilterTitle>
            <Checkbox
              checked={useLeadListFilters}
              onChange={() => onUseLeadListFiltersChanged(!useLeadListFilters)}
            />
          </FilterCell>
        </FilterRow>
        <FilterRow>
          <FilterCell>
            <FilterTitle>Minimum % Projected Eligible Spend (T6M)</FilterTitle>
            <Input
              type="number"
              placeholder="0 - 100"
              value={minimumPercentageProjectedEligibleInvoiceSpendInput}
              onChange={onMinimumPercentageProjectedEligibleInvoiceSpendChanged}
              onFinalize={(v) => onMinimumPercentageProjectedEligibleInvoiceSpendFinalized(v)}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Minimum Eligible Spend (T6M)</FilterTitle>
            <Input
              type="number"
              placeholder="Value"
              value={minimumEligibleInvoiceSpendInput}
              onChange={onMinimumEligibleInvoiceSpendChanged}
              onFinalize={(v) => onMinimumEligibleInvoiceSpendFinalized(v)}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Minimum Eligible WAPT (T12M)</FilterTitle>
            <Input
              type="number"
              placeholder="Value in days"
              value={minimumWeightedEligibleAveragePaymentTenorInput}
              onChange={onMinimumWeightedEligibleAveragePaymentTenorChanged}
              onFinalize={(v) => onMinimumWeightedEligibleAveragePaymentTenorFinalized(v)}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Maximum Eligible WAPT (T12M)</FilterTitle>
            <Input
              type="number"
              placeholder="Value in days"
              value={maximumWeightedEligibleAveragePaymentTenorInput}
              onChange={onMaximumWeightedEligibleAveragePaymentTenorChanged}
              onFinalize={(v) => onMaximumWeightedEligibleAveragePaymentTenorFinalized(v)}
            />
          </FilterCell>
        </FilterRow>
        <FilterRow>
          <FilterCell>
            <FilterTitle>Is Mesa Vendor</FilterTitle>
            <Select
              value={isInternalVendorInput}
              onChange={onIsInternalVendorChanged}
              options={BooleanSelectionOptions}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Is Preferred Vendor</FilterTitle>
            <Select
              value={isPreferredVendorInput}
              onChange={onIsPreferredVendorChanged}
              options={BooleanSelectionOptions}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Is Vendor In HubSpot</FilterTitle>
            <Select
              value={isVendorInHubSpotInput}
              onChange={onIsVendorInHubSpotChanged}
              options={BooleanSelectionOptions}
            />
          </FilterCell>
        </FilterRow>
      </FilterSection>

      <Table
        headerFontSize="11px"
        rowFontSize="11px"
        columns={ExternalVendorColumns}
        rows={rows}
        conditionalRowStyling={internalVendorRowStyling}
        currentSortColumn={sortField}
        currentSortDirection={sortDirection}
        setSortField={(field: string) => dispatch(updateSortField(field))}
        setSortDirection={(direction: api.SortDirection) => dispatch(updateSortDirection(direction))}
        onRowClick={(row: Record<string, unknown>) => navigate(`/partners/${row.partnerId}/${qualifier}-vendors/${row.externalVendorId}`)}
        paginationComponent={(
          <PaginationFooter
            selectedLimit={limit}
            limits={DefaultPaginationLimits}
            onChange={(item) => {
              dispatch(updateLimit(item));
              dispatch(updatePage(1));
            }}
            currentPage={page}
            totalPages={totalPages || 1}
            onPrev={() => dispatch(updatePage(page - 1))}
            onNext={() => dispatch(updatePage(page + 1))}
          />
        )}
      />
    </VendorsPageContainer>
  );
}

export default ExternalVendorsPageTemplate;
