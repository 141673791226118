import { types as api } from '@mesa-labs/mesa-api';
import ReactApexChart from 'react-apexcharts';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Card,
  CardContainer,
  CardTitle,
  DownloadButton,
  PaginationFooter,
  ResourceCard,
  ResourceSection,
  Table,
} from '@mesa-labs/mesa-ui';
import { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import {
  exportExternalClientInvoices,
  exportExternalClientVendors,
  useGetExternalClientInvoicesQuery,
  useGetExternalClientQuery,
  useGetExternalClientVendorsQuery,
} from '../../redux/api/externalVendors';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateExternalVendorsLimit,
  updateExternalVendorsPage,
  updateExternalVendorsSortDirection,
  updateExternalVendorsSortField,
  updateExternalVendorsTotalPages,
  updateExternalInvoicesTotalPages,
  updateExternalInvoicesServicedByMesa,
  updateExternalInvoicesSortField,
  updateExternalInvoicesSortDirection,
  updateExternalInvoicesLimit,
  updateExternalInvoicesPage
} from '../../redux/slices/externalClients';
import { DefaultPaginationLimits } from './PaginationLimits';
import { asExportFilter, stackedHorizontalBarChartOptions } from '../../utils';
import { ExternalClientVendorColumns } from './ExternalClientVendors';
import { FilterCell, FilterControls, FilterControlsButtonContainer, FilterRow, FilterSection } from './Filters';
import { OptionalBooleanDropdown } from './OptionalDropdown';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type ExternalClientVendorsPageTemplate = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalClientPageTemplate(props: ExternalClientVendorsPageTemplate): React.ReactElement | null {
  const { businessEntityScope } = props;
  const { partnerId, id: externalClientId } = useParams();
  const dispatch = useDispatch();
  const externalVendorsPage = useSelector((state) => state.externalClients.externalVendorsPage) || 1;
  const externalVendorsTotalPages = useSelector((state) => state.externalClients.externalVendorsTotalPages);
  const externalVendorsLimit = useSelector((state) => state.externalClients.externalVendorsLimit) || DefaultPaginationLimits[0];
  const externalVendorsSortField = useSelector((state) => state.externalClients.externalVendorsSortField) || 'totalInvoiceSpend';
  const externalVendorsSortDirection = useSelector((state) => state.externalClients.externalVendorsSortDirection) || api.SortDirection.DESCENDING;
  const externalInvoicesServicedByMesa = useSelector((state) => state.externalClients.externalInvoicesServicedByMesa);
  const externalInvoicesPage = useSelector((state) => state.externalClients.externalInvoicesPage) || 1;
  const externalInvoicesTotalPages = useSelector((state) => state.externalClients.externalInvoicesTotalPages);
  const externalInvoicesLimit = useSelector((state) => state.externalClients.externalInvoicesLimit) || DefaultPaginationLimits[0];
  const externalInvoicesSortField = useSelector((state) => state.externalClients.externalInvoicesSortField);
  const externalInvoicesSortDirection = useSelector((state) => state.externalClients.externalInvoicesSortDirection);
  const partnerIdParam = parseInt(partnerId || '', 10);

  const {
    data: externalClient,
  } = useGetExternalClientQuery({
    partnerId: partnerIdParam,
    externalClientId: externalClientId!,
    businessEntityScope
  }, { skip: !externalClientId });

  const filter = {
    sortField: externalVendorsSortField,
    sortDirection: externalVendorsSortDirection,
    page: externalVendorsPage,
    limit: externalVendorsLimit.value,
    businessEntityScope
  };

  const {
    data: {
      data: externalVendors,
      total: totalExternalVendors,
    } = {},
  } = useGetExternalClientVendorsQuery({
    partnerId: partnerIdParam,
    externalClientId: externalClientId!,
    ...filter,
  }, { skip: !externalClientId });

  useEffect(() => {
    if (totalExternalVendors !== undefined) {
      const limitValue = externalVendorsLimit.value || externalVendorsLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalVendorsTotalPages(Math.ceil(totalExternalVendors / limitValue)));
    }
  }, [totalExternalVendors, externalVendorsLimit]);

  const externalInvoicesFilter = {
    partnerId: partnerIdParam,
    externalClientId: externalClientId!,
    businessEntityScope,
    servicedByMesa: externalInvoicesServicedByMesa,
  };
  const {
    data: {
      data: externalInvoices,
      total: totalExternalInvoices,
    } = {}
  } = useGetExternalClientInvoicesQuery(externalInvoicesFilter, { skip: !externalClientId });

  useEffect(() => {
    if (totalExternalInvoices !== undefined) {
      const limitValue = externalInvoicesLimit.value || externalInvoicesLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalInvoicesTotalPages(Math.ceil(totalExternalInvoices / limitValue)));
    }
  }, [totalExternalInvoices, externalInvoicesLimit]);

  const timelineSeries: ApexAxisChartSeries = useMemo(() => {
    if (!externalClient) {
      return [];
    }

    return [
      {
        name: 'Weighted Average Approval Time',
        data: [{ x: 'Time (Days)', y: externalClient.weightedAverageApprovalTime }]
      },
      {
        name: 'Weighted Average Import Lateness',
        data: [{ x: 'Time (Days)', y: externalClient.weightedAverageImportLateness }]
      },
      {
        name: 'Weighted Average Pre-Float Period',
        data: [{ x: 'Time (Days)', y: externalClient.weightedAveragePreFloatPeriod }]
      },
      {
        name: 'Weighted Average Float Period',
        data: [{ x: 'Time (Days)', y: externalClient.weightedAverageFloatPeriod }]
      },
    ];
  }, [externalClient]);

  const vendorRestrictions = useMemo(() => externalClient?.vendorRestrictions?.map(vr => `${vr.externalVendorId} (${vr.restrictionType})`).join(','), [externalClient]);

  if (!externalClient) {
    return null;
  }

  const externalClientColumns: Array<[string, Array<string | ResourceItemProp>]> =
    [
      ['Basic Info',
        [
          { key: 'partnerName', label: 'Partner' },
          { key: 'externalClientId', label: 'External Client Id' },
          { key: 'defaultPaymentTermsDays', label: 'Default Payment Terms' },
          { key: 'segment', label: 'Client Segment' },
          { key: 'division', label: 'Division'},
          { key: 'exclusionCode', label: 'Exclusion Code' },
          { key: 'exclusionReason', label: 'Exclusion Reason' },
          { key: 'vendorRestrictions', label: 'Vendor Restrictions' },
        ]
      ],
      ['Invoice/Vendor Counts',
        [
          { key: 'eligibleInvoiceCountT6m', label: 'Eligible Invoices (T6M)' },
          { key: 'eligibleInvoiceCountT12m', label: 'Eligible Invoices (T12M)', width: '75%' },
          { key: 'invoicesAccelerated', label: 'Invoices Accelerated', width: '100%' },
          { key: 'totalVendors', label: 'Total Vendors' },
          { key: 'totalInternalVendors', label: 'Total Signed Vendors' },
          { key: 'totalServicedVendorsT12m', label: 'Total Funded Vendors (T12M)', width: '50%' },
        ]
      ],
      ['Invoice Spend/Acceleration',
        [
          { key: 'totalInvoiceSpendT12m', label: 'Total Invoice Spend (T12M)', type: 'currency' },
          { key: 'totalInternalInvoiceSpendT12m', label: 'Total Signed Invoice Spend (T12M)', type: 'currency' },
          { key: 'totalServicedInvoiceSpendT12m', label: 'Total Funded Invoice Spend (T12M)', type: 'currency', width: '50%' },
          { key: 'monthlyInvoiceSpendT6m', label: 'Monthly Spend (T6M)', type: 'currency' },
          { key: 'monthlyInvoiceSpendStdDevT6m', label: 'Monthly Spend Standard Deviation (T6M)', type: 'currency', width: '75%' },
          { key: 'monthlyInvoiceSpendT12m', label: 'Monthly Spend (T12M)', type: 'currency' },
          { key: 'monthlyInvoiceSpendStdDevT12m', label: 'Monthly Spend Standard Deviation (T12M)', type: 'currency', width: '75%'  },
          { key: 'invoiceAmountAccelerated', label: 'Invoices Accelerated (Amount)', type: 'currency' },
          { key: 'revenueGenerated', label: 'Revenue Generated', type: 'currency', width: '75%'  },
        ]
      ],
      ['Timeline',
        [
          { key: 'weightedAveragePaymentTenorT6m', label: 'WAPT (T6M)' },
          { key: 'weightedAveragePaymentTenorT12m', label: 'WAPT (T12M)', width: '75%' },
          { key: 'weightedAverageLedgerLatenessT6m', label: 'WALL (T6M)' },
          { key: 'weightedAverageLedgerLatenessT12m', label: 'WALL (T12M)', width: '75%' },
          { key: 'weightedAverageApprovalTime', label: 'Weighted Average Approval Time' },
          { key: 'weightedAverageImportLateness', label: 'Weighted Average Import Lateness' },
          { key: 'weightedAveragePreFloatPeriod', label: 'Weighted Average Pre-Float Period' },
          { key: 'weightedAverageFloatPeriod', label: 'Weighted Average Float Period' },
        ]
      ],
      ['Conversion, Yield, Failure %',
        [
          { key: 'internalVendorsConversion', label: 'Vendor Conversion', type: 'percentage' },
          { key: 'internalInvoiceSpendConversionT12m', label: 'Invoice Spend Conversion (T12M)', type: 'percentage', width: '75%' },
          { key: 'weightedEligibleAverageNet30AprT6m', type: 'percentage', label: 'Weighted Net 30 APR (T6M)' },
          { key: 'weightedEligibleAverageNet30AprT12m', type: 'percentage', label: 'Weighted Net 30 APR (T12M)', width: '75%' },
          { key: 'weightedEligibleAverageNet30YieldPricingT6m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T6M)' },
          { key: 'weightedEligibleAverageNet30YieldPricingT12m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T12M)', width: '75%' },
          { key: 'weightedAverageDiscountRate', type: 'percentage', label: 'Weighted Discount Rate' },
          { key: 'weightedAverageApr', type: 'percentage', label: 'Weighted Average APR', width: '75%' },
          { key: 'internalPercentageFailingNet30', type: 'percentage', label: 'Internal Invoices Failing Net-30' },
          { key: 'internalPercentageFailingNet60', type: 'percentage', label: 'Internal Invoices Failing Net-60' },
          { key: 'internalPercentageSettledWithin3', type: 'percentage', label: 'Internal Invoices Settled In 3', width: '50%' },
          { key: 'externalPercentageFailingNet30', type: 'percentage', label: 'External Invoices Failing Net-30' },
          { key: 'externalPercentageFailingNet60', type: 'percentage', label: 'External Invoices Failing Net-60' },
          { key: 'externalPercentageSettledWithin3', type: 'percentage', label: 'External Invoices Settled In 3', width: '50%' }
        ]
      ],
    ];

  return (
    <ClientPageContainer>
      <CardContainer>
        <ResourceCard
          resource={{
            ...externalClient,
            partnerName: api.getPartnerName(externalClient.partnerId),
            vendorRestrictions,
            currency: 'USD', // TODO: MESA-1457: Multi-currency support
          }}
          title={externalClient.externalClientName || externalClient.externalClientId}
          items={[]}
          sectionedItems={externalClientColumns}
          columns={4}
        />
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Weighted Average Invoice Timeline</CardTitle>
          <ReactApexChart options={stackedHorizontalBarChartOptions()} series={timelineSeries} type="bar"
            height={350} />
        </Card>
      </CardContainer>
      <ResourceSection
        title="External Vendors (T12M)"
        controls={(
          <DownloadButton
            text="Download CSV"
            data={() => exportExternalClientVendors(partnerIdParam, externalClient.externalClientId, {
              ...asExportFilter(filter),
            })}
            fileName="external-client-vendors.csv"
          />
        )}
      >
        <Table
          columns={ExternalClientVendorColumns}
          rows={
            (externalVendors || []).map((externalVendor) => ({
              ...externalVendor,
              partnerName: api.getPartnerName(externalVendor.partnerId),
              id: `${externalVendor.partnerId}:${externalVendor.externalVendorId}`,
              isInternalVendor: !!externalVendor.internalVendorId,
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          currentSortColumn={externalVendorsSortField}
          currentSortDirection={externalVendorsSortDirection}
          setSortField={(field: string) => dispatch(updateExternalVendorsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalVendorsSortDirection(direction))}
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalVendorsLimit}
              onChange={(item) => {
                dispatch(updateExternalVendorsLimit(item));
                dispatch(updateExternalVendorsPage(1));
              }}
              currentPage={externalVendorsPage}
              totalPages={externalVendorsTotalPages || 0}
              onPrev={() => dispatch(updateExternalVendorsPage(externalVendorsPage - 1))}
              onNext={() => dispatch(updateExternalVendorsPage(externalVendorsPage + 1))}
            />
          )}
        />
      </ResourceSection>

      <ResourceSection
        title="Invoices"
      >
        <FilterSection
          filterControl={(
            <FilterControls>
              <FilterControlsButtonContainer>
                <DownloadButton
                  text="Download CSV"
                  data={() => exportExternalClientInvoices(partnerIdParam, externalClientId!, {
                    ...asExportFilter(externalInvoicesFilter),
                  })}
                  fileName={`external-client-${externalClientId}-invoices.csv`}
                />
              </FilterControlsButtonContainer>
            </FilterControls>
          )}
        >
          {businessEntityScope !== api.BusinessEntityScope.Internal ? (
            <FilterRow columns={4}>
              <FilterCell>
                <OptionalBooleanDropdown
                  label="Serviced By Mesa"
                  selectedOption={externalInvoicesServicedByMesa}
                  onChange={(value) => dispatch(updateExternalInvoicesServicedByMesa(value))}
                />
              </FilterCell>
            </FilterRow>
          ) : (<></>)}
        </FilterSection>

        <Table
          columns={[
            {
              key: 'invoiceNumber',
              sortField: 'invoiceNumber',
              label: 'Invoice Number',
              width: '20%',
            },
            {
              key: 'invoiceDate',
              sortField: 'invoiceDate',
              label: 'Invoice Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'generalLedgerDate',
              sortField: 'generalLedgerDate',
              label: 'General Ledger Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'dueDate',
              sortField: 'dueDate',
              label: 'Due Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'paymentDate',
              sortField: 'paymentDate',
              label: 'Payent Date',
              width: '15%',
              type: 'date',
            },
            {
              key: 'grossAmount',
              sortField: 'grossAmount',
              label: 'Amount',
              width: '10%',
              type: 'currency',
            },
            {
              key: 'servicedByMesa',
              sortField: 'servicedByMesa',
              label: 'Service By Mesa',
              width: '10%',
            },
          ]}
          currentSortColumn={externalInvoicesSortField}
          currentSortDirection={externalInvoicesSortDirection}
          setSortField={(field: string) => dispatch(updateExternalInvoicesSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalInvoicesSortDirection(direction))}
          rows={
            (externalInvoices || []).map((externalInvoice) => ({
              ...externalInvoice,
              id: `${externalInvoice.partnerId}:${externalInvoice.externalClientId}:${externalInvoice.externalDocumentId}:${externalInvoice.documentType}`,
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalInvoicesLimit}
              onChange={(item) => {
                dispatch(updateExternalInvoicesLimit(item));
                dispatch(updateExternalInvoicesPage(1));
              }}
              currentPage={externalInvoicesPage}
              totalPages={externalInvoicesTotalPages || 0}
              onPrev={() => dispatch(updateExternalInvoicesPage(externalInvoicesPage - 1))}
              onNext={() => dispatch(updateExternalInvoicesPage(externalInvoicesPage + 1))}
            />
          )}
        />
      </ResourceSection>
    </ClientPageContainer>
  );
}

export default ExternalClientPageTemplate;
