import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Provider } from 'react-redux';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import { LegalStyles, theme, StyleSheetManager, Typography } from '@mesa-labs/mesa-ui';

import Routes from '../routes';
import Navigation, { BackLink, BackLinkIcon } from './common/Navigation';
import { useSelector } from '../redux/hooks';
import { getImpersonatedVendorId } from '../redux/slices/auth';
import cognitoService from '../cognito';
import { combineReducers, configureStore, createReducer } from '@reduxjs/toolkit';
import persistStore from 'redux-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  #modal-root {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  #notification-root {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  body {
    font-family: ${(props) => props.theme.fonts.inter};
    padding: 0px;
    margin: 0px;

    input:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
  }
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: +1;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: table;
    margin: -0.5em;
    z-index: +1;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
    z-index: +1;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: table;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: table;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;

const Layout = styled.div`
  font-size: 16px;
  height: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
`;

const NavigationContainer = styled.div`
  position: relative;
`;

const MainContainer = styled.div<{ marginTop: number }>`
  margin-top: ${(props) => props.marginTop}px;
  padding: 0px 32px 32px 32px;
  position: relative;
`;

const Header = styled(Typography.Title)`
  color: ${(props) => props.theme.colors.Midnight};
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1.12px;
  line-height: 125%;
  padding: 12px 0px 24px 0px;
`;

const routeToHeader: [RegExp, string][] = [
  [/^\/vendors$/, 'Vendors'],
  [/^\/vendors\/.+\/documents\/.+/, 'Vendor Document Details'],
  [/^\/vendors\/.+\/serviceAgreements\/.+/, 'Service Agreement Details'],
  [/^\/vendors\/.+$/, 'Vendor Details'],
  [/^\/enablement$/, 'Enablement'],
  [/vendor-surveys$/, 'Vendor Surveys'],
  [/^\/invoices$/, 'Invoices'],
  [/programs$/, 'Programs'],
  [/invoices\/.+\/documents\/.+/, 'Invoice Document Details'],
  [/invoices\/.+$/, 'Invoice Details'],
  [/invoice-line-items$/, 'Invoice Line Items'],
  [/invoice-line-items\/.*/, 'Invoice Line Item Details'],
  [/customer-batch-transfers/, 'Transfers'],
  [/onboardings$/, 'Onboardings'],
  [/onboardings\/.*/, 'Onboarding Details'],
  [/businesses\/.*/, 'Business Details'],
  [/journal/, 'Journal'],
  [/projections/, 'Projections'],
  [/tasks/, 'Tasks'],
  [/external-vendor-statistics/, 'External Vendor Statistics'],
  [/^statistics$/, 'Statistics'],
  [/configuration-settings/, 'Configuration Settings'],
  [/key-performance-indicators/, 'KPIs'],
  [/accelerated-payout/, 'Accelerated Payout Statistics'],
  [/adjustments$/, 'Adjustments'],
  [/adjustments\/.*/, 'Adjustment Details'],
  [/adjustment-groups$/, 'Adjustment Groups'],
  [/adjustment-groups\/.*/, 'Adjustment Group Details'],
  [/invoice-adjustment-approvals/, 'Invoice Adjustment Approvals'],
  [/vendor-documents/, 'Vendor Documents'],
  [/collections$/, 'Collections'],
  [/collections\/.*/, 'Collection Details'],
  [/reconciliation$/, 'Reconciliation'],
  [/batch-sweeps/, 'Batch Sweeps'],
  [/reconciliation\/.*/, 'Reconciliation Details'],
  [/jll-service-agreements$/, 'JLL Service Agreement History'],
  [/service-agreements$/, 'Service Agreement History'],
  [/service-agreements\/compare\/.*/, 'Service Agreement Comparison'],
  [/^\/clients\/.*/, 'Clients'],
  [/external-clients$/, 'External Clients'],
  [/external-clients\/.*/, 'External Client Details'],
  [/external-vendors$/, 'External Vendors'],
  [/external-client-vendors$/, 'External Client Vendors'],
  [/internal-clients$/, 'Internal Clients'],
  [/internal-clients\/.*/, 'Internal Client Details'],
  [/internal-vendors$/, 'Internal Vendors'],
  [/internal-client-vendors$/, 'Internal Client Vendors'],
  [/facilitators/, 'Facilitators'],
  [/abandoned-users/, 'Abandoned Users'],
  [/eligible-invoices/, 'Eligible Invoices'],
  [/revenue/, 'Revenue'],
  [/cohorts/, 'Cohorts'],
  [/^\/anomalies$/, 'Anomalies'],
  [/anomalies\/.+$/, 'Anomaly Details'],
  [/settings/, 'Settings'],
];

const routeToBackButton: RegExp[] = [
  /vendors\/.*/,
  /enablement\/.*/,
  /invoices\/.*/,
  /invoice-line-items\/.*/,
  /onboardings\/.*/,
  /collections\/.*/,
  /reconciliation\/.*/,
  /clients\/.*/,
  /anomalies\/.*/,
];

function Content({ children }: { children: ReactNode }): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const authAttributes = useSelector((state) => state.auth.attributes);

  const pageHeader = useMemo(() => {
    const match = routeToHeader.find((route) => route[0].test(location.pathname));

    if (match) {
      return match[1];
    }

    return null;
  }, [location.pathname]);


  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isImpersonatingVendor = useMemo(() => isLoggedIn ? getImpersonatedVendorId(authAttributes) : undefined, [isLoggedIn, authAttributes]);
  const showBackButton = useMemo(() => routeToBackButton.find((route) => route.test(location.pathname)), [location.pathname]);

  return (
    <ContentContainer>
      <NavigationContainer>
        <Navigation />
        <MainContainer marginTop={isImpersonatingVendor ? 124 : 84}>
          {showBackButton && (
            <BackLink onClick={() => navigate(-1)}>
              <BackLinkIcon src="/assets/back-icon.svg" />
              Back
            </BackLink>
          )}
          {pageHeader && <Header>{pageHeader}</Header>}
          {children}
        </MainContainer>
      </NavigationContainer>
    </ContentContainer>
  );
}

const handleAuth = async () => {
  const user = cognitoService.getCurrentUser();
  if (!user) {
    return;
  }

  const session = await cognitoService.getCurrentSession();
  if (!session) {
    return;
  }

  const { createConfiguredStore } = await import('../redux/store');
  const store = createConfiguredStore();

  return store;
};

function App(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(configureStore({
    reducer: combineReducers({
      auth: createReducer({}, () => ({})),
    }),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
  }));
  const [persistor] = useState(persistStore(store));

  useEffect(() => {
    (async () => {
      setLoading(true);

      const authStore = await handleAuth();

      if (authStore) {
        setStore(authStore);
        persistor.persist();
      }

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Provider store={store}>
      <StyleSheetManager>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <LegalStyles />

          <Layout id="operations-ui">
            <Router>
              <Content>
                <Routes />
              </Content>
            </Router>
          </Layout>
        </ThemeProvider>
      </StyleSheetManager>
    </Provider>
  );
}

export default App;
